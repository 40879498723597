//React Modules
import { memo, useRef, useState, Fragment, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Controller, useForm as useFormHook } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { debounce } from "lodash";


import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TextField,
    DialogContent,
    DialogActions,
    Autocomplete,
    CircularProgress,
    FormControl,
    Switch,
    FormControlLabel,
    FormGroup,
    Box
} from '@mui/material';


// Interfaces
import {
    InterfaceFormCreateProject,
    InterfacePropsDataMenuProject,
} from "../../interfaces/project";

import {
    InterfaceTableBase,
    InterfaceTableForm,
    InterfaceTableContent,
    InterfaceTableContentFilter,
} from "../../interfaces/table";
import { InterfaceProject } from "../../../interfaces/project";

//styles
import { NavbarLink } from "../../../styles/nav";
import { StyledMultiline } from "../../styles/materialUi";

import {
    ButtonComponent,
    DoubleContainer,
    StyledAutocomplete,
    TitleWrapper,
    ReturnIco,
    CursorPointer
} from "../../../styles/globalComponents";
import {
    LabelInput,
    SubLabelInput,
    WrapperInput,
} from "../../../zUsers/styles/Input";

//Components
import { AutocompleteComponent } from "../../../components/autocomplete";
import { TablePaginationGeneral } from "../paginate";
import { MoreComponent } from "../more";
import { Loading } from "../../../components/loading";
import { ModalGeneric } from "../../../components/modalGeneric";

//hooks
import { useAppDispatch } from "../../../hooks/redux";
import { useForm } from "../../../hooks/form";

//data
import { dataMenuProject, stateProject } from "../../data/project";

//api
import { usePutDeactivateMutation } from "../../../zUsers/api/auth";
import {
    useGetListProjectAdminQuery,
    usePostProjectAdminMutation,
    usePostSendEmailProjectMutation,
    usePutProjectAODMutation,
    usePutStateProjectMutation,
    useDeleteProjectMutation,
    useExportProjectsMutation,
    useExportScoresMutation
} from "../../api/project";

//utils
import {
    dateComplet,
    dateYears,
    filesProjectState,
} from "../../../utils/utils";
import { InputComponentGeneral } from "../../../components/Input";
import { useGetListAnnouncementsQuery } from "../../api/announcements";

//slices
import { schemaProjectForm } from "../../validators/project";
import { activeAlertAction } from "../../../slices/alerts";
import { InterfaceConvocationsTableBase } from "../../interfaces/convocations";
import { InputComponent } from "../../../zUsers/components/Input";
import { mediaTotal, sumaTotalEvaluaciones } from "../../utils/evaluate";


//translate
import { useTranslation } from "react-i18next";
import { filterDataProjects } from "../../utils/projects";
import { StringDecoder } from "string_decoder";
import { Announcement } from "@mui/icons-material";

export const TableProjectForm = ({
    title,
    colum,
    select,
    props,
}: InterfaceTableBase): JSX.Element => {
    const [t, i18n] = useTranslation("global");
    //state
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [open, setOpen] = useState(false);

    //apis
    const { isLoading: isLoadingA, currentData: Announcements } =
        useGetListAnnouncementsQuery();

    // Crear proyecto
    const [postProjectAdmin, { isLoading: isLoadingProject }] =
        usePostProjectAdminMutation();

    // Exportar proyectos
    const [exportProjects, { isLoading: isExportingProject }] = useExportProjectsMutation();
    const handleExportProjects = async () => {
        try {
            // Hacer la petición para obtener los datos JSON
            const json = await exportProjects().unwrap(); // Esto devuelve el JSON, no CSV

            if (!json || json.length === 0) {
                console.error('No data available for export');
                return;
            }

            // Crear un arreglo para almacenar filas procesadas
            const excelData: any[] = [];

            json.forEach((project: { numeroProyecto: any; tituloProyecto: any; fechaRegistroRepresentante: any; archivos: any; numeroDeParticipantes: any; tipoParticipacion: any; fechaCreacion: any; premio: any; representative: { rol: any; nombreCompleto: any; email: any; telefono: any; fechaDeNacimiento: any; edad: any; dni: any; curso: any; nombreCentro: any; correoElectronicoCentro: any; direccion: any; ciudad: any; provincia: any; pais: any; }; estudiantes: any; tutores: any; }) => {

                if (!project) {
                    console.warn('Skipping project due to missing data:', project);
                    return;
                }

                const baseData = {
                    'Número de Proyecto': project.numeroProyecto || '',
                    'Título del Proyecto': project.tituloProyecto || '',
                    'Fecha Registro Representante': project.fechaRegistroRepresentante || '',
                    'Archivos': project.archivos || '',
                    'Número de Participantes': project.numeroDeParticipantes || '',
                    'Tipo de Participación': project.tipoParticipacion || '',
                    'Fecha de Creación': project.fechaCreacion || '',
                    'Premio': project.premio || ''
                };

                // Agregar línea para el representante (si existe)
                excelData.push({
                    ...baseData,
                    'Rol': project.representative ? project.representative.rol || '' : '',
                    'Nombre Completo': project.representative ? project.representative.nombreCompleto || '' : '',
                    'Email': project.representative ? project.representative.email || '' : '',
                    'Teléfono': project.representative ? project.representative.telefono || '' : '',
                    'Fecha de Nacimiento': project.representative ? project.representative.fechaDeNacimiento || '' : '',
                    'Edad': project.representative ? project.representative.edad || '' : '',
                    'DNI': project.representative ? project.representative.dni || '' : '',
                    'Curso': project.representative ? project.representative.curso || '' : '',
                    'Centro': project.representative ? project.representative.nombreCentro || '' : '',
                    'Email del Centro': project.representative ? project.representative.correoElectronicoCentro || '' : '',
                    'Dirección': project.representative ? project.representative.direccion || '' : '',
                    'Ciudad': project.representative ? project.representative.ciudad || '' : '',
                    'Provincia': project.representative ? project.representative.provincia || '' : '',
                    'País': project.representative ? project.representative.pais || '' : ''
                });

                // Agregar líneas para cada estudiante
                (project.estudiantes || []).forEach((student: { rol: any; nombreCompleto: any; email: any; telefono: any; fechaDeNacimiento: any; edad: any; dni: any; curso: any; nombreCentro: any; direccion: any; ciudad: any; provincia: any; pais: any; }) => {
                    excelData.push({
                        ...baseData,
                        'Rol': student.rol || '',
                        'Nombre Completo': student.nombreCompleto || '',
                        'Email': student.email || '',
                        'Teléfono': student.telefono || '',
                        'Fecha de Nacimiento': student.fechaDeNacimiento || '',
                        'Edad': student.edad || '',
                        'DNI': student.dni || '',
                        'Curso': student.curso || '',
                        'Centro': student.nombreCentro || '',
                        'Dirección': student.direccion || '',
                        'Ciudad': student.ciudad || '',
                        'Provincia': student.provincia || '',
                        'País': student.pais || ''
                    });
                });

                // Agregar líneas para cada tutor
                (project.tutores || []).forEach((tutor: { nombreCompleto: any; email: any; telefono: any; tipoTutor: any; dni: any; }) => {
                    excelData.push({
                        ...baseData,
                        'Rol': 'Tutor',
                        'Nombre Completo': tutor.nombreCompleto || '',
                        'Email': tutor.email || '',
                        'Teléfono': tutor.telefono || '',
                        'Tipo de Tutor': tutor.tipoTutor || '',
                        'DNI': tutor.dni || ''
                    });
                });
            });

            // Crear un nuevo libro de Excel
            const workbook = XLSX.utils.book_new();

            // Convertir los datos a una hoja de cálculo de Excel
            const worksheet = XLSX.utils.json_to_sheet(excelData);

            // Añadir la hoja de cálculo al libro
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Projects');

            // Generar el archivo Excel y descargarlo
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            // Obtener la fecha actual para incluirla en el nombre del archivo
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0]; // Formato: YYYY-MM-DD

            // Descargar el archivo con el nombre dinámico
            saveAs(blob, `projects_export_${formattedDate}.xlsx`);
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    // Exportar evaluaciones
    const [exportEvaluations, { isLoading: isExportingEvaluations }] = useExportScoresMutation();
    const handleExportEvaluations = async () => {
        try {
            // Obtener los datos JSON del backend
            const json = await exportEvaluations().unwrap();

            if (!json || json.length === 0) {
                console.error('No data available for export');
                return;
            }

            // Crear un arreglo para almacenar filas procesadas
            const excelData: any[] = [];

            json.forEach((evaluation: {
                numeroProyecto: any;
                nombreProyecto: any;
                autorProyecto: any;
                cursoProyecto: any;
                tutorProyecto: any;
                centroEscolarProyecto: any;
                evaluador: any;
                informeProcedimiento: any;
                materialRecopilado: any;
                fuentes: any;
                conclusiones: any;
                presentacion: any;
                suma: any;
                comentarios: any;
            }) => {
                excelData.push({
                    'Número de Proyecto': evaluation.numeroProyecto || '',
                    'Nombre del Proyecto': evaluation.nombreProyecto || '',
                    'Autor del Proyecto': 
                        evaluation.autorProyecto === 'undefined undefined' 
                            ? 'Sin autor / Autor borrado' 
                            : (evaluation.autorProyecto || ''),
                    'Curso del Proyecto': evaluation.cursoProyecto || '',
                    'Tutor del Proyecto': evaluation.tutorProyecto || '',
                    'Centro Escolar': evaluation.centroEscolarProyecto || '',
                    'Evaluador': evaluation.evaluador || '',
                    'Informe de Procedimiento': evaluation.informeProcedimiento || 0,
                    'Material Recopilado': evaluation.materialRecopilado || 0,
                    'Fuentes': evaluation.fuentes || 0,
                    'Conclusiones': evaluation.conclusiones || 0,
                    'Presentación': evaluation.presentacion || 0,
                    'Suma': evaluation.suma || 0,
                    'Comentarios': evaluation.comentarios || ''
                });
            });
            
            // Crear un nuevo libro de Excel
            const workbook = XLSX.utils.book_new();

            // Convertir los datos a una hoja de cálculo de Excel
            const worksheet = XLSX.utils.json_to_sheet(excelData);

            // Añadir la hoja de cálculo al libro
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Evaluaciones');

            // Generar el archivo Excel y descargarlo
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            // Obtener la fecha actual para incluirla en el nombre del archivo
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().split('T')[0]; // Formato: YYYY-MM-DD

            // Descargar el archivo con el nombre dinámico
            saveAs(blob, `evaluations_export_${formattedDate}.xlsx`);
        } catch (error) {
            console.error('Error exporting evaluations:', error);
        }
    };


    const { handleChange, page, rowsPerPage, search, data, count } =
        useForm<InterfaceTableForm<InterfaceProject>>({
            count: 0,
            page: 0,
            rowsPerPage: 9999,
            search: "",
            filter1: "",
            filter2: "",
            data: [],
        });


    const [filter1, setFilter1] = useState('')
    const [filter2, setFilter2] = useState('')

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useFormHook<InterfaceFormCreateProject>({
        defaultValues: {
            name: "",
            dateAnnouncements: "",
            hasTutor: true,
            isGroupWork: true,
        },
        resolver: yupResolver(schemaProjectForm(t)),
    });

    const {
        isLoading,
        currentData: rows,
        refetch,
        isFetching,
    } = useGetListProjectAdminQuery({
        skip: rowsPerPage * page,
        limit: rowsPerPage,
    });

    //Dispath redux
    const dispatch = useAppDispatch();

    if (rows && rows.count) {
        if (count != rows.count) {
            handleChange({ field: "count", value: rows.count });
        }
    }

    //filter data of select
    const filterDataSelect = (
        data: InterfaceProject[],
        key: keyof InterfaceProject
    ) => {
        const dataReturn: Array<string> = [];
        for (const items of data) {
            if (key === "jurors") {
                if (items.jurors) {
                    for (const itemsRecords of items.jurors) {
                        if (!dataReturn.includes(itemsRecords.firstName)) {
                            dataReturn.push(itemsRecords.firstName);
                        }
                    }
                }
            } else if (key === "announcement") {
                if (items.announcement &&
                    !dataReturn.includes(
                        items.announcement.name +
                        " " +
                        dateYears(items.announcement.initialDate)
                    )
                ) {
                    dataReturn.push(
                        items.announcement.name +
                        " " +
                        dateYears(items.announcement.initialDate)
                    );
                }
            }
        }
        return dataReturn;
    };

    // Change generic select filter camp
    const changeSelectGeneric = (
        value: string,
        index: number,
        key: string
    ) => {
        if (select && select[index]) {
            const name = select[index].name as keyof InterfaceProject;
            if (key === 'filter1') {
                setFilter1(value)
            } else if (key === 'filter2') {
                setFilter2(value)
            }
            !value ? requestSearch("") : requestSearch(value, name);


        }
    };

    //handleSelect1 chaneges
    const handleChangeSelect1 = (data: string) =>
        changeSelectGeneric(data, 0, "filter1");

    //handleSelect2 chaneges
    const handleChangeSelect2 = (data: string) =>
        changeSelectGeneric(data, 1, "filter2");

    //actions
    const handleAndSet = [
        {
            handle: handleChangeSelect1,
            data: filter1,
        },
        {
            handle: handleChangeSelect2,
            data: filter2,
        },
    ];

    //filter search all keys
    const requestSearch = debounce((searchedVal: string, key?: keyof InterfaceProject) => {
        const searchValue = searchedVal.toLowerCase(); // Convertir solo una vez

        const filteredRows = rows
            ? rows.results.filter((row) => {
                // Filtrado específico para `jurors`
                if (key === "jurors" && row.jurors) {
                    return row.jurors.some((juror) =>
                        juror.firstName.toLowerCase().includes(searchValue)
                    );
                }

                // Filtrado específico para `announcement`
                if (key === "announcement" && row.announcement) {
                    const dateAndName = `${row.announcement.name} ${dateYears(row.announcement.initialDate)}`;
                    return dateAndName.toLowerCase().includes(searchValue);
                }

                // Filtrado genérico en todas las propiedades de `filterDataProjects`
                for (const [_, value] of Object.entries(filterDataProjects(row, t))) {
                    if (String(value).toLowerCase().includes(searchValue)) {
                        return true;
                    }
                }

                return false; // Si no coincide en ninguna propiedad
            })
            : [];

        // Actualizamos el estado solo si hay cambios
        handleChange({ field: "search", value: searchedVal });
        handleChange({ field: "data", value: filteredRows });
    }, 300); // Esperar 300 ms antes de ejecutar la función de nuevo

    const [loading, setLoading] = useState(false);

    const cancelSearch = () => {
        setLoading(true); // Activamos el indicador de carga
        handleChange({ field: "search", value: "" });
        requestSearch(""); // Volvemos a cargar todos los proyectos
        setTimeout(() => setLoading(false), 500); // Ocultamos el indicador después de un tiempo breve
    };

    const handleSumbitProject = async (data: InterfaceFormCreateProject) => {
        try {

            setOpenCreateProject(false);

            const request = {
                name: data.name,
                announcement: data.dateAnnouncements,
                hasTutor: data.hasTutor,
                isGroupWork: data.isGroupWork,
            };

            const responseCreate = await postProjectAdmin(request).unwrap();

            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "success",
                    isText: t("zAdmin.components.form.tableProject.update-success"),
                })
            );
        } catch (error) {
            dispatch(
                activeAlertAction({
                    isActive: true,
                    isMode: "error",
                    isText: t("zAdmin.components.form.tableProject.update-error"),
                })
            );
        }
    };

    useEffect(() => {
        if (rows) {
            handleChange({ field: "data", value: rows.results });
        }

        // Volver a cargar los datos del listado de proyectos al regresar a esta página
        refetch();

    }, [rows]);

    return (
        <>
            <TableContainer
                sx={{
                    height: "90%",
                    width: "90%",
                    margin: "auto",
                    marginTop: "2%",
                    padding: "2%",
                    maxHeight: props.maxHeight
                }}
            >
                {/* Title + reverse */}
                <DoubleContainer
                    gridTemplateColumns="auto"
                    display="grid"
                    width="100%"
                >
                    <NavbarLink paddingLeft="0" to="#" onClick={() => window.history.back()}>
                        {" "}
                        <ReturnIco />
                        {t("zAdmin.components.form.tableProject.back")}{" "}
                    </NavbarLink>
                    <TitleWrapper padding="0 0 2% 0"> {title} </TitleWrapper>
                </DoubleContainer>

                {/* Filters */}
                <DoubleContainer alingItems="baseline">
                    {/* Search  */}
                    <DoubleContainer>
                        <WrapperInput heigth={"100"} width="100%">
                            <TextField
                                placeholder={title}
                                size={"small"}
                                onChange={(e) => {
                                    const value = e.target.value.trim();
                                    if (value === "") {
                                        cancelSearch();
                                    } else {
                                        requestSearch(value);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </WrapperInput>

                    </DoubleContainer>

                    {/* Select filter  */}
                    <DoubleContainer>
                        {select &&
                            select.map((items, index) => {
                                return (
                                    <AutocompleteComponent
                                        placeholder={
                                            items.placeholder as string
                                        }
                                        data={filterDataSelect(
                                            rows ? rows.results : [],
                                            items.name as unknown as keyof InterfaceProject
                                        )}
                                        handle={handleAndSet[index].handle}
                                        valueDefault={
                                            handleAndSet[index].data as string
                                        }
                                        key={`select-${index}`}
                                    />
                                );
                            })}

                    </DoubleContainer>

                </DoubleContainer>

                {/* Button actions  */}
                <DoubleContainer width="50%" display="flex">
                    <ButtonComponent
                        paddingLeft="1%"
                        width="auto"
                        heigth="45px"
                        className="btn-hover"
                        type="button"
                        onClick={() => setOpenCreateProject(true)}
                    >
                        {t("zAdmin.components.form.tableProject.new-project")}
                    </ButtonComponent>
                    <ButtonComponent
                        paddingLeft="1%"
                        width="auto"
                        heigth="45px"
                        className="btn-hover"
                        type="button"
                        onClick={() => handleExportProjects()}
                    >
                        {/*t("zAdmin.components.form.tableProject.export")*/}
                        {"Exportar proyectos"}
                    </ButtonComponent>
                    <ButtonComponent
                        paddingLeft="1%"
                        width="auto"
                        heigth="45px"
                        className="btn-hover"
                        type="button"
                        onClick={() => handleExportEvaluations()}
                    >
                        {/*t("zAdmin.components.form.tableProject.export")*/}
                        {"Exportar evaluaciones"}
                    </ButtonComponent>
                    {/* Create project*/}
                    <ModalGeneric
                        isOpen={openCreateProject}
                        handleClose={() => setOpenCreateProject(false)}
                    >
                        <form onSubmit={handleSubmit(handleSumbitProject)}>
                            <DialogContent>
                                <InputComponent
                                    {...register("name")}
                                    errors={errors.name}
                                    label={t("zAdmin.components.form.tableProject.create-new-project")}
                                    type={"text"}
                                    placeholder={t("zAdmin.components.form.tableProject.new-project-place")}
                                    name="name"
                                />

                                <Controller
                                    control={control}
                                    name={"dateAnnouncements"}
                                    render={({
                                        field: { onChange, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Autocomplete
                                            open={open}
                                            onOpen={() => setOpen(true)}
                                            onClose={() => setOpen(false)}
                                            ref={ref}
                                            size="small"
                                            onChange={(event, item) =>
                                                onChange(item ? item._id : "")
                                            }
                                            isOptionEqualToValue={(option, value) =>
                                                `${dateYears(option.initialDate)}-${option.name}` ===
                                                `${dateYears(value.initialDate)}-${value.name}`
                                            }
                                            getOptionLabel={(option) =>
                                                `${dateYears(option.initialDate)}-${option.name}`
                                            }
                                            options={Announcements || []}
                                            loading={isLoadingA}
                                            renderInput={(params) => (
                                                <TextField
                                                    error={!!error}
                                                    helperText={error ? t("zAdmin.components.form.tableProject.conv-required") : ""}
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <Fragment>
                                                                {isLoadingA ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />

                                {/* Adjusted Switches */}
                                <Box mt={2}>
                                    <FormGroup row style={{ gap: '16px' }}>
                                        <FormControlLabel
                                            control={<Switch {...register("isGroupWork")} defaultChecked />}
                                            label={t("zAdmin.components.form.tableProject.is-group-work")}
                                            style={{ marginRight: '8px' }}
                                        />
                                        <FormControlLabel
                                            control={<Switch {...register("hasTutor")} defaultChecked />}
                                            label={t("zAdmin.components.form.tableProject.has-tutor")}
                                        />
                                    </FormGroup>
                                </Box>
                            </DialogContent>

                            <DialogActions style={{ padding: "2%" }}>
                                <ButtonComponent className="btn-hover">
                                    {t("zAdmin.components.form.tableProject.create")}
                                </ButtonComponent>
                            </DialogActions>
                        </form>
                    </ModalGeneric>
                </DoubleContainer>

                {/* Content Tabla */}
                <TableContent colum={colum} rows={data} />

                {/*Paginate 
                <TablePaginationGeneral
                    page={count <= 0 ? 0 : page}
                    rowsPerPage={rowsPerPage}
                    onChange={handleChange}
                    count={count}
                />*/}

                {/* Loading */}
                <Loading open={isLoading || isFetching || isLoadingProject} />
            </TableContainer>
        </>
    );
};

interface InterfaceProjectStatusForm {
    status: string;
}

const TableContent = memo(
    ({ colum, rows }: InterfaceTableContentFilter<InterfaceProject>) => {
        const [t, i18n] = useTranslation("global")
        //navigate
        const navigate = useNavigate();
        //ref
        const rowData = useRef({} as InterfaceProject);
        const textEmail = useRef(
            t("zAdmin.components.form.tableProject.info-delivered")
        );

        //form hooks
        const { handleChange, status } = useForm<InterfaceProjectStatusForm>({
            status: "",
        });


        //event
        const dispatch = useAppDispatch();

        //state
        const [changeStateP, setChangeStateP] = useState(false);
        //actions menu
        const [openSendEmailIndividual, setOpenSendEmailIndividual] =
            useState(false);
        //api
        const [putStateProject, { isLoading: isLoadingSP }] =
            usePutStateProjectMutation();

        const [putProjectAOD, { isLoading: isLoadingAOD }] =
            usePutProjectAODMutation();

        const [postSendEmailProject, { isLoading: isLoadingSEP }] =
            usePostSendEmailProjectMutation();

        const [deleteProject, { isLoading: isLoadingDeleteProject }] =
            useDeleteProjectMutation();



        const handleChangeStateProject = async () => {
            const { _id: id } = rowData.current;
            setChangeStateP(false);
            if (!status) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zAdmin.components.form.tableProject.pick-option"),
                    })
                );
            }
            try {
                const respDeactivate = await putStateProject({
                    id,
                    status,
                }).unwrap();

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zAdmin.components.form.tableProject.change-state"),
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zAdmin.components.form.tableProject.error-state"),
                    })
                );
            }
        };

        //Nueva opción para borrar usuarios (experimental)
        //Evento que se hace al pulsarlo
        const handleDeleteProject = async () => {
            try {
                const { _id: id } = rowData.current;
                const respDeleteProject = await deleteProject({
                    id,
                }).unwrap();

                if ("response" in respDeleteProject) {
                    return dispatch(
                        activeAlertAction({
                            isActive: true,
                            isMode: "error",
                            // isText: `Ocurrió un error al ${textActive} un proyecto`,
                            isText: `${t("zAdmin.components.form.tableProject.delete-project-error")} 
                                     ${"Error"}`,
                        })
                    );
                }

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: `${t("zAdmin.components.form.tableProject.delete-project-success")}`,
                    })
                );

            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: `${"Error"} 
                                 ${t("zAdmin.components.form.tableProject.delete-project-error")}`,
                    })
                );
            }
        };





        const handleSendEmail = async () => {
            const { _id: proyect } = rowData.current;
            const textInformation = textEmail.current;
            setOpenSendEmailIndividual(false);
            try {
                const respDeactivate = await postSendEmailProject({
                    proyect,
                    textInformation,
                }).unwrap();

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zAdmin.components.form.tableProject.email-members"),
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zAdmin.components.form.tableProject.error-email"),
                    })
                );
            }
        };

        const handleProejctAOD = async () => {
            const { _id: id } = rowData.current;
            try {
                const respDeactivate = await putProjectAOD({
                    id,
                }).unwrap();

                dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "success",
                        isText: t("zAdmin.components.form.tableProject.deactivate-project"),
                    })
                );
            } catch (error) {
                return dispatch(
                    activeAlertAction({
                        isActive: true,
                        isMode: "error",
                        isText: t("zAdmin.components.form.tableProject.error-deactivate"),
                    })
                );
            }
        };

        const actionsMenu: InterfacePropsDataMenuProject = {
            editNameProject: () =>
                navigate(`/project-admin/${rowData.current._id}`),
            readMembers: () => navigate(`/member/${rowData.current._id}`),
            editStateProejct: () => {
                setChangeStateP(true);
            },
            off: () => handleProejctAOD(),
            sendEmails: () => setOpenSendEmailIndividual(true),
            deleteProject: () => handleDeleteProject(),
        };


        const [isLoadingSorting, setLoadingSorting] = useState(false);

        //Constantes para el tema del ordenador por campos
        const [sortField, setSortField] = useState("");
        const [sortDirection, setSortDirection] = useState("asc");
        const sortableColumns = ['name', 'emailRepresent', 'state', 'stateActive', 'media', 'createdAt', 'convocations', 'statusDelivery'];
        const isColumnSortable = (columnName: string) => sortableColumns.includes(columnName);


        const compareStrings = (a: string, b: string, direction: string) => {
            if (a < b) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a > b) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        };

        const compareNumber = (a: number, b: number, direction: string) => {
            if (direction === 'asc') {
                return a - b
            } else {
                return b - a
            }
        }

        const compareByField = (field: string, a: InterfaceProject, b: InterfaceProject, direction: string) => {

            //console.log(field)

            switch (field) {
                case 'name':
                    return compareStrings(a.name || '', b.name || '', direction);
                case 'emailRepresent':
                    return compareStrings(
                        a.representative && a.representative.email ? a.representative.email : '',
                        b.representative && b.representative.email ? b.representative.email : '',
                        direction
                    );
                case 'state':
                    return compareStrings(a.status || '', b.status || '', direction);
                case 'stateActive':
                    return a.active === b.active ? 0 : (a.active ? 1 : -1);
                case 'media':
                    //var mediaA = mediaTotal(a.evaluations) !== null ? mediaTotal(a.evaluations) : 0;
                    //var mediaB = mediaTotal(b.evaluations) !== null ? mediaTotal(b.evaluations) : 0;
                    var sumaA = sumaTotalEvaluaciones(a.evaluations) || 0; // Usamos sumaTotal en vez de mediaTotal
                    var sumaB = sumaTotalEvaluaciones(b.evaluations) || 0;

                    return compareNumber(sumaA, sumaB, direction);
                //return compareNumber(mediaA, mediaB, direction)
                case 'createdAt':
                    return compareStrings(a.createdAt || '', b.createdAt || '', direction);
                case 'convocations':
                    return compareStrings(a.announcement.name || '', b.announcement.name || '', direction);
                case 'statusDelivery':
                    return compareStrings(a.statusDelivery || '', b.statusDelivery || '', direction);
                default:
                    return 0;
            }
        };

        const sortedData = [...rows].sort((a, b) => {
            return compareByField(sortField, a, b, sortDirection);
        });


        const renderSortIcon = (field: string) => {
            if (isColumnSortable(field) && field === sortField) {
                return sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />;
            }

            return null;
        };

        //Ordenado
        const handleSort = (field: string) => {
            setLoadingSorting(true);

            // Verifica si el mismo campo se está ordenando nuevamente para cambiar la dirección
            const direction = field === sortField && sortDirection === "asc" ? "desc" : "asc";

            //console.log(rows.length)

            setTimeout(() => {

                //console.log(field)
                setSortField(field); // Actualiza el campo actualmente ordenado

                setSortDirection(direction);
                // Actualiza la dirección de ordenamiento

                setLoadingSorting(false);

            }, 500)


        };

        return (
            <>
                <Table
                    sx={{ minWidth: 500 }}
                    aria-label="custom pagination table"
                >

                    <TableHead>
                        <TableRow>

                            {colum.map((items) => (
                                <TableCell
                                    key={items.id}
                                    align={items.align}
                                    style={{
                                        minWidth: items.minWidth,
                                        fontWeight: items.fontWeight,
                                        cursor: isColumnSortable(items.id) ? 'pointer' : 'default',
                                    }}
                                    onClick={isColumnSortable(items.id) ? () => handleSort(items.id) : undefined}
                                >

                                    {items.label}
                                    {renderSortIcon(items.id)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedData &&
                            sortedData.map((row, index) => (
                                <TableRow key={row._id}>
                                    {/* Primera celda para el número de proyecto */}
                                    <TableCell align="center">
                                        {row.projectNumber ? row.projectNumber : 'N/A'}
                                    </TableCell>
                                    <TableCell

                                        style={{
                                            width: 160,
                                            textDecorationLine: "underline",
                                            cursor: "pointer"
                                        }}
                                        align="center"
                                        onClick={() =>
                                            navigate(
                                                `/project-admin/${row._id}`
                                            )
                                        }
                                    >
                                        {row.name}
                                    </TableCell>

                                    <TableCell
                                        style={{ width: 160, wordBreak: 'break-word' }}
                                        align="center"
                                    >
                                        {row.representative
                                            ? row.representative.email ||
                                            t("zAdmin.components.form.tableProject.w-representative")
                                            : t("zAdmin.components.form.tableProject.w-representative")
                                        }
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.status ? row.status : t("zAdmin.components.form.tableProject.w-status")}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.active
                                            ? row.active
                                                ? t("zAdmin.components.form.tableProject.act")
                                                : t("zAdmin.components.form.tableProject.deactivate")
                                            : t("zAdmin.components.form.tableProject.act")}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.evaluations && row.evaluations.length > 0
                                            ? sumaTotalEvaluaciones(row.evaluations)
                                            : t("zAdmin.data.project.without-score")}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {dateComplet(row.createdAt)}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {filesProjectState(row, t)}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        {row.announcement
                                            ? `${dateYears(
                                                row.announcement.initialDate
                                            )}-${row.announcement.name}`
                                            : t("zAdmin.components.form.tableProject.w-convocation")}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            textDecorationLine: "underline",
                                            cursor: "pointer"
                                        }}
                                        align="center"
                                        onClick={() =>
                                            navigate(`/evaluations/${row._id}`)
                                        }
                                    >
                                        {"ver evaluaciones"}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            width: 160,
                                            cursor: "pointer"
                                        }}
                                        align="center"
                                        onClick={() => {
                                            rowData.current = row;
                                        }}
                                    >
                                        <MoreComponent
                                            items={dataMenuProject(t, actionsMenu)}
                                            props={{}}
                                            key={`moreComponentaa-${row._id}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>

                </Table>



                {/* send email a people project */}
                <ModalGeneric
                    isOpen={openSendEmailIndividual}
                    handleClose={() => setOpenSendEmailIndividual(false)}
                >
                    <DialogContent>
                        <WrapperInput>
                            <LabelInput htmlFor={"sendEmail"}>
                                {t("zAdmin.components.form.tableProject.send-email")}
                            </LabelInput>
                            <SubLabelInput>
                                {t("zAdmin.components.form.tableProject.text-email")}
                            </SubLabelInput>
                            <StyledMultiline
                                id="outlined-multiline-static"
                                onChange={(e) => {
                                    textEmail.current = e.target.value;
                                }}
                                style={{ width: "100%" }}
                                multiline
                                rows={4}
                                defaultValue={t("zAdmin.components.form.tableProject.info-days")}
                            />
                        </WrapperInput>
                    </DialogContent>
                    <DialogActions style={{ padding: "2%" }}>
                        <ButtonComponent
                            className="btn-hover"
                            type="button"
                            onClick={() => handleSendEmail()}
                        >
                            {t("zAdmin.components.form.tableProject.send")}
                        </ButtonComponent>
                    </DialogActions>
                </ModalGeneric>

                {/* change state project */}
                <ModalGeneric
                    isOpen={changeStateP}
                    handleClose={() => {
                        setChangeStateP(false);
                    }}
                >
                    <DialogContent>
                        <AutocompleteComponent
                            data={stateProject}
                            handle={(data: string) =>
                                handleChange({ field: "status", value: data })
                            }
                            valueDefault={status}
                            label={t("zAdmin.components.form.tableProject.state-project")}
                            subLabel={t("zAdmin.components.form.tableProject.state-project-text")}
                            placeholder={""}
                        />
                    </DialogContent>
                    <DialogActions style={{ padding: "2%" }}>
                        <ButtonComponent
                            className="btn-hover"
                            type="button"
                            onClick={() => handleChangeStateProject()}
                        >
                            {t("zAdmin.components.form.tableProject.modify-state")}
                        </ButtonComponent>
                    </DialogActions>
                </ModalGeneric>
                {/* Loading */}
                <Loading open={isLoadingSP || isLoadingAOD || isLoadingSEP || isLoadingSorting || isLoadingDeleteProject} />

            </>
        );
    }
);
